import { Container } from '@mui/material'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  removeAllPersonGeneTests,
  removePersonCancerRiskProperty,
  removePersonColonoscopyStatus,
  removePersonConditions,
  removePersonFITStatus,
  removePersonGeneTests,
  removePersonGeneticTesting,
  removePersonNameProperty,
  removePersonPropertiesList,
  removePersonProperty,
  removePersonSurgeries,
  setPersonAdopted,
  setPersonAgeAtMenarche,
  setPersonAgeAtMenopause,
  setPersonAvgCigarettes,
  setPersonBreastBiopsyResult,
  setPersonBreastMammogramStatus,
  setPersonColonoscopyStatus,
  setPersonConditions,
  setPersonConsumesAlcohol,
  setPersonDrinkAmount,
  setPersonDrinkingFrequency,
  setPersonElevatedPSA,
  setPersonFITStatus,
  setPersonGenderIdentity,
  setPersonGeneTests,
  setPersonGeneticTesting,
  setPersonHrtType,
  setPersonHrtUsage,
  setPersonLastNameAtBirth,
  setPersonMenopauseStatus,
  setPersonOralContraceptiveUsage,
  setPersonPreviousBreastBiopsies,
  setPersonPropertiesIsPresent,
  setPersonProstateBiopsy,
  setPersonSex,
  setPersonSmoking,
  setPersonSmokingYears,
  setPersonSurgeries,
  setPersonTreatments,
  setPersonTypeOfDrink,
  setState,
} from '../../redux/actionCreators'
import Context from '../../utils/context/Context'
import { getLabel, renderContent, subQuestions } from '../../utils/helpers'
import {
  PersonDiagnosticTests,
  PersonFieldPaths as paths,
} from '../../utils/QuestionnaireStateManager'
import CancerHistory from '../../widgets/CancerHistory'
import CollapsibleQuestion from '../../widgets/CollapsibleQuestion'
import DateOfBirth from '../../widgets/DateOfBirth'
import FullName, { PreviousName } from '../../widgets/FullName'
import GenderIdentity from '../../widgets/GenderIdentity'
import LifeStatus from '../../widgets/LifeStatus'
import MeasurementsSection from '../../widgets/MeasurementsSection'
import SexAssignedAtBirth from '../../widgets/SexAssignedAtBirth'
import Adoption from './Adoption'
import Alcohol from './Alcohol'
import BreastBiopsy from './BreastBiopsy'
import BreastMammogram from './BreastMammogram'
import Cancer from './Cancer'
import Colonoscopy from './Colonoscopy'
import Conditions from './Conditions'
import ContraceptivePills from './ContraceptivePills'
import ElevatedPSA from './ElevatedPSA'
import Endometriosis from './Endometriosis'
import FIT from './FIT'
import GeneticTesting from './GeneticTesting'
import HRT from './HRT'
import MantleRadiation from './MantleRadiation'
import Medications from './Medications'
import Menopause from './Menopause'
import MenstrualPeriod from './MenstrualPeriod'
import ProstateBiopsy from './ProstateBiopsy'
import RecreationalDrugs from './RecreationalDrugs'
import Surgeries from './Surgeries'
import Tobacco from './Tobacco'
import Twins from './Twins'

class Person extends Component {
  static contextType = Context

  constructor(props) {
    super(props)

    const {
      questionnaire: { persons, probandId },
      resolvePersonId = undefined,
    } = props

    // only for family members and relatives. (not for proband)
    if (resolvePersonId) {
      this.resolvePersonIdWithCreate = _.partial(resolvePersonId, true)
    }

    this.designatedPersonId = `${this.getPersonId() || probandId}`

    const { sex = '', genderIdentity = '' } = persons[this.designatedPersonId] || {}

    let identityValue = null

    if (genderIdentity) {
      identityValue = 'Y'
    } else if (['M', 'F'].includes(sex)) {
      identityValue = 'N'
    }

    this.state = {
      genderIdentity: identityValue,
    }
  }

  componentDidMount() {
    this.context.setLoading(() => {})
  }

  render() {
    const {
      targetPerson,
      questionnaire: { persons, probandId },
      configuration: { sectionConfig },
      localization,
    } = this.props
    const { sex = '' } = persons[probandId]
    const isProband = targetPerson === 'proband'
    const personId = isProband ? probandId : this.getPersonId() || this.resolvePersonIdWithCreate

    const configStep = () => {
      const enableFamilyCheck = (targetFamily, targetPerson) =>
        sectionConfig[targetFamily].enable
          ? sectionConfig[targetFamily].subSectionConfig[targetPerson]
          : {}

      switch (targetPerson) {
        case 'proband':
          return sectionConfig['your-information']
        case 'mother':
        case 'father':
          return enableFamilyCheck('your-family', targetPerson)
        case 'mat-gmother':
          return enableFamilyCheck('mothers-family', 'maternal-grandmother')
        case 'mat-gfather':
          return enableFamilyCheck('mothers-family', 'maternal-grandfather')
        case 'pat-gmother':
          return enableFamilyCheck('fathers-family', 'paternal-grandfather')
        case 'pat-gfather':
          return enableFamilyCheck('fathers-family', 'paternal-grandfather')
        default:
          return {}
      }
    }
    const config = configStep().questions

    const renderMap = {
      name: (
        <FullName
          key="full-name"
          id={personId}
          isProband={targetPerson === 'proband'}
          localization={localization}
          config={subQuestions({ config, key: 'name' })}
        />
      ),
      namePrevious: (
        <CollapsibleQuestion key="pre-name" label="Has your name changed?">
          <PreviousName id={personId} localization={localization} />
        </CollapsibleQuestion>
      ),
      dateOfBirth: <DateOfBirth key="personDOB" id={personId} />,
      // TODO: TEMPORARY WHILE WAITING ON PFQ-778
      sexAtBirth: isProband ? (
        <SexAssignedAtBirth
          key="SexAssignedAtBirth"
          label="Sex assigned at birth *"
          id={personId}
          clearSexSpecificQuestions={isProband && this.clearSexSpecificQuestions}
        />
      ) : null,
      notCisgender: (
        <GenderIdentity
          key="GenderIdentity"
          isGenderDifferent={this.state.genderIdentity}
          updateGenderDifferent={(e) => {
            this.setState({ genderIdentity: e })
          }}
          patient="patient"
        />
      ),
      measurement: (
        <MeasurementsSection
          key="MeasurementsSection"
          id={probandId}
          config={subQuestions({ config, key: 'measurement' })}
        />
      ),
      twin: this.renderDeclareTwins(),
      menstrualAge: this.renderFirstMenstrualPeriod(),
      oralContraceptive: (
        <ContraceptivePills
          key="ContraceptivePills"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'oral-contraceptive' })}
        />
      ),
      menopause: (
        <Menopause
          key="Menopause"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'menopause' })}
        />
      ),
      hrtUse: (
        <HRT
          key="hrt"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'hrt-use' })}
        />
      ),
      tobacco: (
        <Tobacco
          key="tob"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'tobacco' })}
        />
      ),
      alcohol: (
        <Alcohol
          key="alc"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'alcohol' })}
        />
      ),
      cancerHx: (
        <Cancer key="can" id={probandId} patient={'patient'}>
          <CancerHistory
            label={getLabel(localization, paths.TYPES_OF_CANCER, 'person')}
            personId={probandId}
            config={subQuestions({ config, key: 'cancer-hx' })}
          />
        </Cancer>
      ),
      mantleRadiation: <MantleRadiation key="mra" id={probandId} />,
      endometriosisStatus: <Endometriosis key="end" id={probandId} patient="patient" />,
      fitHx: (
        <FIT
          key="fit"
          id={probandId}
          patient="patient"
          fitDiagnostic={this.getDiagnosticTest(PersonDiagnosticTests.FIT)}
          config={subQuestions({ config, key: 'fit-hx' })}
        />
      ),
      colonoscopyHx: (
        <Colonoscopy
          key="col"
          id={probandId}
          patient="patient"
          colonoscopyDiagnostic={this.getDiagnosticTest(PersonDiagnosticTests.COLONOSCOPY)}
          config={subQuestions({ config, key: 'colonoscopy-hx' })}
        />
      ),
      breastMammogram: (
        <BreastMammogram
          key="bm"
          id={probandId}
          patient="patient"
          breastMammogram={this.getDiagnosticTest(PersonDiagnosticTests.BREAST_MAMMOGRAM)}
          config={subQuestions({ config, key: 'breast-mammogram' })}
        />
      ),
      breastBiopsy: (
        <BreastBiopsy
          key="bb"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'breast-biopsy' })}
        />
      ),
      prostateBiopsy: (
        <ProstateBiopsy
          key="pb"
          id={probandId}
          config={subQuestions({ config, key: 'prostate-biopsy' })}
        />
      ),
      psaResult: this.renderElevatedPSAStatus(sex),
      surgeries: this.renderFemaleSurgeries(sex),
      conditions: (
        <Conditions key="Conditions" config={subQuestions({ config, key: 'conditions' })} />
      ),
      geneticTestHistory: (
        <GeneticTesting
          key="gt"
          id={probandId}
          patient="patient"
          config={subQuestions({ config, key: 'genetic-test-history' })}
        />
      ),
      medications: <Medications key="med" id={probandId} />,
      recreationalDrugs: <RecreationalDrugs key="rec-drugs" id={probandId} />,
      adopted: this.renderAdoptionInfo(targetPerson),
      lifeStatus: (
        <LifeStatus
          key="LifeStatus"
          personId={personId}
          config={subQuestions({ config, key: 'lifeStatus' })}
        />
      ),
      fatherAdopted: this.renderAdoptionInfo(targetPerson),
      motherAdopted: this.renderAdoptionInfo(targetPerson),
    }

    const content = []
    renderContent({ renderMap, config, content })

    return <Container sx={{ gap: 4, pb: 8 }}>{content}</Container>
  }

  renderDeclareTwins = () => {
    const {
      questionnaire: { persons, probandId },
      setPersonPropertiesIsPresent,
      localization,
    } = this.props

    const { sex, properties } = persons[probandId]
    const { isPresent = '' } = properties.find(({ type }) => type === 'twin') || {}

    return (
      <Twins
        key="Twins"
        label={getLabel(localization, paths.IS_TWIN, 'patient')}
        onChange={async (value) => {
          if (value === 'U' || value === 'N') {
            await this.removePersonsTwin()
          }

          isPresent !== value &&
            setPersonPropertiesIsPresent({ id: probandId, type: 'twin', value })
        }}
        selectedValue={isPresent}
        orderSchema={['Y', 'N', 'U']}
        path={'yesNoUnknown'}
        person={'patient'}
        disabled={[sex === 'U']}
      />
    )
  }

  renderFirstMenstrualPeriod = () => {
    const {
      setPersonAgeAtMenarche,
      questionnaire: { persons, probandId },
      localization,
    } = this.props

    const { cancerRiskData: { ageAtMenarche = '' } = {}, sex } = persons[probandId]

    return sex === 'F' ? (
      <MenstrualPeriod
        key="MenstrualPeriod"
        label={getLabel(localization, paths.AGE_AT_MENARCHE, 'patient')}
        value={ageAtMenarche}
        onChange={(value) => {
          if (value !== ageAtMenarche) {
            setPersonAgeAtMenarche({ id: probandId, value })
          }
        }}
      />
    ) : null
  }

  renderElevatedPSAStatus = (probandSex) => {
    const {
      questionnaire: { probandId },
      setPersonElevatedPSA,
      localization,
    } = this.props

    const elevatedPSADiagnostic = this.getDiagnosticTest(PersonDiagnosticTests.ELEVATED_PSA)

    return probandSex === 'M' ? (
      <ElevatedPSA
        key="ElevatedPSA"
        label={getLabel(localization, paths.ELEVATED_PSA_LABEL, 'patient')}
        conditionState={elevatedPSADiagnostic.isPerformed}
        onConditionStateChange={(value) => {
          if (!elevatedPSADiagnostic.type) {
            setPersonElevatedPSA({ id: probandId, type: 'type', value: 'elevatedPSA' })
          }
          if (elevatedPSADiagnostic?.isPerformed !== value) {
            setPersonElevatedPSA({ id: probandId, type: 'isPerformed', value })
          }
        }}
      />
    ) : null
  }

  renderFemaleSurgeries = (probandSex) => {
    return probandSex === 'F' ? <Surgeries key="Surgeries" /> : null
  }

  renderAdoptionInfo = (targetPerson) => {
    const {
      questionnaire: { probandId },
      type,
    } = this.props

    const personId =
      type !== 'proband' ? this.getPersonId() || this.resolvePersonIdWithCreate : probandId

    return <Adoption key="Adoption" personId={personId} targetPerson={targetPerson} type={type} />
  }

  /**
   * Remove relatives's `twin` attribute in `properties`.
   * @param {String} newSex A gender clicked by the user.
   */
  removePersonsTwin = async () => {
    const {
      removePersonPropertiesList,
      questionnaire: { persons },
    } = this.props

    for (const personId in persons) {
      const twin = persons[personId].properties?.find((property) => property.type === 'twin')

      if (twin) {
        removePersonPropertiesList({ id: personId, type: 'twin' })
      }
    }
  }

  /**
   * Reset all properties that are sex specific
   */
  clearSexSpecificQuestions = async (probandSex) => {
    const { questionnaire, setState: setQuestionnaireState } = this.props
    const { persons, probandId } = questionnaire
    const proband = persons[probandId]

    const {
      cancerRiskData = {},
      genderIdentity = '',
      name = {},
      conditions = [],
      surgeries = [],
      diagnosticTests = [],
      properties = [],
    } = proband

    const _proband = {
      ...proband,
      name: { ...name },
      conditions: [...conditions],
      surgeries: [...surgeries],
      diagnosticTests: [...diagnosticTests],
      cancerRiskData: { ...cancerRiskData },
      properties: [...properties],
      sex: probandSex,
    }

    if (this.state.genderIdentity) {
      this.setState({
        ...this.state,
        genderIdentity: null,
      })
    }

    if (genderIdentity) {
      delete _proband.genderIdentity
    }

    if (name?.lastNameAtBirth) {
      delete _proband.name.lastNameAtBirth

      if (Object.values(_proband.name).every((value) => !value)) {
        delete _proband.name
      }
    }

    const conditionIndex = conditions.findIndex(({ label }) => label === 'Endometriosis')

    if (conditionIndex > -1) {
      const deletedConditions = [..._proband.conditions]
      deletedConditions.splice(conditionIndex, 1)

      _proband.conditions = deletedConditions
    }

    if (surgeries?.length > 0) {
      _proband.surgeries = []
    }

    if (diagnosticTests?.length > 0) {
      _proband.diagnosticTests = []
    }

    await this.removePersonsTwin()

    if (!cancerRiskData) return

    if (cancerRiskData.ageAtMenarche) {
      delete _proband.cancerRiskData.ageAtMenarche
    }

    if (cancerRiskData.oralContraceptiveUsage) {
      delete _proband.cancerRiskData.oralContraceptiveUsage
    }

    if (cancerRiskData.menopauseStatus) {
      delete _proband.cancerRiskData.menopauseStatus
    }

    if (cancerRiskData.ageAtMenopause) {
      delete _proband.cancerRiskData.ageAtMenopause
    }

    if (cancerRiskData.hrtUsage) {
      delete _proband.cancerRiskData.hrtUsage
    }

    if (cancerRiskData.hrtType) {
      delete _proband.cancerRiskData.hrtType
    }

    // missed before (It is only for female in the logic of the render function)
    if (cancerRiskData.previousBreastBiopsies) {
      delete _proband.cancerRiskData.previousBreastBiopsies
    }

    // missed before (It is only for female in the logic of the render function)
    if (cancerRiskData.breastBiopsyResult) {
      delete _proband.cancerRiskData.breastBiopsyResult
    }

    setQuestionnaireState({
      ...questionnaire,
      persons: {
        ...questionnaire.persons,
        [probandId]: _proband,
      },
    })
  }

  getDiagnosticTest = (type) => {
    const {
      questionnaire: { persons, probandId },
    } = this.props
    const { diagnosticTests = [] } = persons[probandId]

    return _.find(diagnosticTests, { type }) || {}
  }

  // It works only for the not proband persons
  getPersonId = () => {
    const { questionnaire, resolvePersonId, type } = this.props

    if (type !== 'proband') {
      return resolvePersonId(false, questionnaire)
    }

    return null
  }
}

const mapStateToProps = ({ questionnaire, localization, configuration }) => ({
  questionnaire,
  localization,
  configuration,
})

export default connect(mapStateToProps, {
  removePersonConditions,
  removePersonPropertiesList,
  removePersonProperty,
  setPersonAgeAtMenarche,
  setPersonAgeAtMenopause,
  setPersonHrtType,
  setPersonHrtUsage,
  setPersonGenderIdentity,
  setPersonLastNameAtBirth,
  setPersonMenopauseStatus,
  setPersonOralContraceptiveUsage,
  setPersonPropertiesIsPresent,
  setPersonSex,
  removePersonNameProperty,
  setPersonSmoking,
  removePersonCancerRiskProperty,
  setPersonAvgCigarettes,
  setPersonSmokingYears,
  setPersonConsumesAlcohol,
  setPersonTypeOfDrink,
  setPersonDrinkAmount,
  setPersonDrinkingFrequency,
  setPersonTreatments,
  removePersonFITStatus,
  setPersonFITStatus,
  setPersonColonoscopyStatus,
  removePersonColonoscopyStatus,
  setPersonBreastMammogramStatus,
  setPersonProstateBiopsy,
  setPersonPreviousBreastBiopsies,
  setPersonBreastBiopsyResult,
  setPersonConditions,
  setPersonElevatedPSA,
  removePersonSurgeries,
  setPersonSurgeries,
  setPersonGeneticTesting,
  removeAllPersonGeneTests,
  removePersonGeneticTesting,
  removePersonGeneTests,
  setPersonGeneTests,
  setPersonAdopted,
  setState,
})(Person)
