import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import { Auth } from '../api/Auth'
import Navigation from '../containers/Navigation'
import { useActions } from '../hooks/useActions'
import Context from '../utils/context/Context'
import DialogWindow from '../widgets/DialogWindow'

export const SessionContext = createContext({})

// timer until the user sees prompt (20min)
const PRE_PROMPT_TIMEOUT = 1000 * 60 * 20
// timer the prompt gives to the user (2min)
const PROMPT_TIMEOUT = 1000 * 60 * 2
// total time until user is logged out
const SESSION_TIMEOUT = PROMPT_TIMEOUT + PRE_PROMPT_TIMEOUT

const SessionTimeout = ({ children }) => {
  const history = useHistory()
  const { sendStateData } = useActions()
  const {
    authState: { isAuthenticated },
    setTimeoutModal,
    timeoutModal,
    setLoading,
  } = useContext(Context)
  const [remaining, setRemaining] = useState(Math.ceil(SESSION_TIMEOUT / 1000))
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    // onMessage,
    timeout: SESSION_TIMEOUT,
    promptBeforeIdle: PROMPT_TIMEOUT,
    events: [],
    crossTab: true,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  // This is necessary so we don't re-render children on remaining time updates
  const value = useMemo(() => ({ activate }), [activate])

  return (
    <SessionContext.Provider value={value}>
      <Navigation activate={activate}>{children}</Navigation>
      <DialogWindow
        open={timeoutModal.open && timeoutModal.type === 'warning'}
        onClose={async () => {
          await handleContinue()
        }}
        handleConfirm={async () => {
          await handleContinue()
            // handling the other browser tabs
            .then(() => {
              setLoading((prevState) => ({
                ...prevState,
                dialog: false,
              }))
            })
            .catch(() => {})
        }}
        title="Session expiring"
        text={
          <>
            Your session will expire in <b>{remaining}</b> seconds. Please click Resume Session if
            you would like to continue working.
          </>
        }
        color="warning"
        primaryBtn={`Resume Session`}
        noSecondary
      />
    </SessionContext.Provider>
  )

  async function handleContinue() {
    await Auth.getSession()
    setTimeoutModal({
      type: '',
      open: false,
    })

    activate()
  }

  async function onIdle() {
    setTimeoutModal(() => ({
      type: '',
      open: false,
    }))

    await sendStateData()
    await Auth.logout()
    await history.push('/user-logout', {
      message: `Your session has expired. To protect your information,
      you have been logged out.`,
    })

    setTimeoutModal(() => ({
      type: 'timedOut',
      open: true,
    }))
  }

  function onPrompt() {
    if (isAuthenticated && !timeoutModal.open) {
      setTimeoutModal({
        type: 'warning',
        open: true,
      })
    }
  }
}

export default SessionTimeout
